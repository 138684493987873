import React from "react";
import Image from "next/image";
import AuraGlow from "@/assets/AuraGlow.png";

export const GradientGlow: React.FC = () => (
  <div className="absolute bottom-[-60%] left-1/2 transform -translate-x-1/2 w-[950px] h-[860px]">
    <Image src={AuraGlow} alt="glow" layout="fill" objectFit="cover" />
  </div>
);

export default GradientGlow;
