"use client";

import React from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";
import EmailIcon from "@/assets/icons/email-icon.png";
import MetamaskIcon from "@/assets/icons/metamasky.png";
import CoinbaseIcon from "@/assets/icons/coinbase.png";
import MoreOptionsIcon from "@/assets/icons/more-options-icon.png";
import ArrowIcon from "@/assets/icons/arrow-icon.png";

const PrivyLogin = () => {
  const router = useRouter();

  const handleMetamaskClick = () => {
    router.push("/auth/post-auth");
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      {/* Email Login */}
      <button className="w-[327px] h-[50px] bg-dark-ruby rounded-full flex items-center px-6 text-soft-pink font-poppins text-sm font-medium">
        <span className="mr-4">
          <Image src={EmailIcon} alt="Email Icon" width={24} height={24} />
        </span>
        your@email.com
      </button>

      {/* Coinbase Wallet */}
      <button className="w-[327px] h-[50px] bg-dark-ruby rounded-full flex items-center px-6 text-soft-pink font-poppins text-sm font-medium">
        <span className="mr-4">
          <Image src={CoinbaseIcon} alt="Coinbase Icon" width={24} height={24} />
        </span>
        Coinbase Wallet
      </button>

      {/* Metamask Wallet */}
      <button
        onClick={handleMetamaskClick}
        className="w-[327px] h-[50px] bg-dark-ruby rounded-full flex items-center px-6 text-soft-pink font-poppins text-sm font-medium"
      >
        <span className="mr-4">
          <Image src={MetamaskIcon} alt="Metamask Icon" width={24} height={24} />
        </span>
        Metamask
      </button>

      {/* More Options */}
      <button className="w-[327px] h-[50px] border border-soft-pink rounded-full flex items-center px-6 text-soft-pink font-poppins text-sm font-medium justify-between">
        <span className="flex items-center space-x-4">
          <Image src={MoreOptionsIcon} alt="More Options Icon" width={24} height={24} />
          <span>More Options</span>
        </span>
        <Image src={ArrowIcon} alt="Arrow Icon" width={16} height={14} />
      </button>

      {/* Forgot Login */}
      <a href="/forgot" className="text-soft-pink font-poppins text-base underline">
        forgot login?
      </a>
    </div>
  );
};

export default PrivyLogin;
