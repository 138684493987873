"use client";

import { MainLayout } from "@/components/Layout/MainLayout";
import { AuraText } from "@/components/Common/Typography";
import { GradientGlow } from "@/components/Layout/Gradient";
import PrivyLogin from "@/components/Auth/PrivyLogin";
import Image from "next/image";
import LogoAura from "@/assets/Logos/LogoAura.png";

const AuthPage = () => {
  return (
    <MainLayout>
      <div className="relative z-10 flex flex-col min-h-screen">
        {/* Top Logo */}
        <div className="flex justify-center pt-[60px]">
          <Image src={LogoAura} alt="aura" width={120} height={60} priority />
        </div>

        {/* Auth Section */}
        <div className="flex-1 flex items-center justify-center">
          <PrivyLogin />
        </div>

        {/* Bottom Copyright */}
        <div className="pb-8 text-center">
          <AuraText variant="copyright">©aura 2024</AuraText>
        </div>
      </div>
      <GradientGlow />
    </MainLayout>
  );
};

export default AuthPage;
