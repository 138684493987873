export const AuraText = ({
  children,
  variant = "h1",
  className = "",
}: {
  children: React.ReactNode;
  variant?: "h1" | "copyright" | "title" | "button" | "link";
  className?: string;
}) => {
  const styles = {
    h1: "font-poppins text-[#EACDD2] text-[18.27px] leading-[32.94px] tracking-[1.46px] font-normal",
    copyright: "font-poppins text-white text-[10.96px] leading-[16.45px] font-medium",
    title: "font-poppins text-[#EACDD2] text-[17px] leading-[22px] font-normal",
    button: "font-poppins text-[#EACDD2] text-[16px] leading-[24px] font-medium",
    link: "font-poppins text-[#EACDD2] text-[17px] leading-[22px] font-normal underline",
  };

  return <span className={`${styles[variant]} ${className}`}>{children}</span>;
};
